import RightIcon from "../../assets/icons/RightIcon";
import Button from "../../components/Button";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "src/Redux/userSlice";
import apiClients from "src/apiClients/apiClients";
import ContactForm from "src/components/ContactForm";
import Footer from "src/components/Footer";
import { UseAuth } from "src/utils/UseAuth/UseAuth";
const Pricing = () => {
  const navigate = useNavigate();
  const auth = UseAuth(true);
  const dispatch = useDispatch();
  const [pricing, setPricing] = useState();
  // const [subs_exp_date, setsubs_exp_date] = useState(null);
  // const [currentDate, setCurrentDate] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (auth.user) {
      dispatch(setUser(auth.user));
      // const inputDateStr = auth?.user?.user?.subscription_expiry_date;

      // if (inputDateStr) {
      //   setsubs_exp_date(new Date(inputDateStr));
      // }

      // setCurrentDate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const fetchData = async () => {
    try {
      const response = await await apiClients.pricing();
      if (response.data) {
        setPricing(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Header
        backgroundImage={"/cover_images/pricing.svg"}
        pageTitle={"Pricing"}
        subTitle={
          "Affordable plans tailored for every learner—find the perfect fit with Atlearn"
        }
        cta={"Subscribe now"}
      />
      <div className="mt-[50px]  flex flex-wrap">
        {pricing?.map((item, index) => (
          <div
            key={index}
            style={{ padding: "115px 72px 40px 72px", borderRadius: "30px" }}
            className=" relative box-border m-auto min-h-80 w-96 
      rounded-md  bg-[#F0F6FA] mt-20 "
          >
            <div>
              <ul className="list-none mb-8 font-fredoka">
                <li className="flex mb-4 gap-3 " id="li-1">
                  <RightIcon /> {item.participants} participants
                </li>
                <li className="flex mb-4 gap-3" id="li-1">
                  <RightIcon /> {item.duration} hours
                </li>

                <li className="flex mb-4 gap-3 " id="li-1">
                  <RightIcon /> {item.Validity}{" "}
                  {item?.period === "day" ? "days" : "months"}
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.chat === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Public / Private Chat
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.sharedNotes === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Shared Notes
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.screenshare === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Screen Sharing
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.sharedRoomAccess === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Share Access
                </li>
                <li className="flex mb-4 gap-3 " id="li-1">
                  <RightIcon /> {item.storage} GB
                </li>
                <li
                  className={`flex mb-4 gap-3 ${
                    item?.recording === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon />{" "}
                  {item.recording === "true" ? "Recordings" : "No Recordings"}
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.breakout === "true" ? `opacity-100` : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Breakout Rooms
                </li>

                <li
                  className={`flex mb-4 gap-3 ${
                    item?.multiuserwhiteboard === "true"
                      ? `opacity-100`
                      : `opacity-25`
                  }`}
                  id="li-1"
                >
                  <RightIcon /> Multi User Whiteboard
                </li>
              </ul>
            </div>
            <Button
              disabled={auth?.user && item?.name === "Free"}
              text={item?.name === "Free" ? "Sign up for free" : "Subscribe"}
              onClick={() => {
                item?.name === "Free"
                  ? navigate("/signup")
                  : navigate(`/checkout/${item?.id}`);
              }}
            />

            <div
              style={{
                margin: "0 10px -50px 10px",
                padding: "25px 30px 22px 30px",
                width: "95%",
                border: "2px solid #490d59",
                borderRadius: "30PX",
                backgroundColor: "#ffffff",
              }}
              className="box-border   
        m4 absolute top-[-50px] left-0"
            >
              <h1
                className="text-2xl font-fredoka font-semibold capitalize  "
                style={{ color: "#000", lineHeight: "1.2" }}
              >
                {item.name}
              </h1>
              <h1
                className="text-[40px] font-fredoka font-bold capitalize  "
                style={{ color: "#e8063c" }}
              >
                 ₹ {item.price}
              </h1>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8">
        <ContactForm />
      </div>

      <Footer />
    </div>
  );
};

export default Pricing;
