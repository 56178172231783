import { useEffect, useRef, useState } from "react";
// import RightIcon from "../assets/icons/RightIcon";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import HeaderText from "../components/HeaderText";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
import { TfiAlarmClock } from "react-icons/tfi";
import Accordion from "../components/Accordion";
import Features from "../components/Features";
import Footer from "../components/Footer";
import backgroundcta from "../assets/img/backgroundd-cta12.webp";
import FAQAtlearn from "../assets/images/home/FAQ-Atlearn.webp";
// import Parentsreview1 from "../assets/img/parents_reviews.png";
import InfoSection from "src/components/InfoSection";
import videoCallIcon from "../assets/img/circlevideo.svg";
import Button from "src/components/Button";
import apiClients from "src/apiClients/apiClients";
import { UseAuth } from "src/utils/UseAuth/UseAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "src/Redux/userSlice";
import CircleIcon from "@mui/icons-material/Circle";
import classRoomIcon from "src/assets/images/home/classeoomIcon.svg"
//data
import {
  slides,
  faqs,
  features,
  PersonalizedLearning,
  CentralizeLearning,
  ForSchool,
  EmployeeTraining,
  AtlearnMobile,
  OutcomeDriven,
  PersonalizedLearningPaths,
  E_learning,
  CourseManagementInfo,
  CourseManagementCards,
} from "src/Data/homePageData";
import InfoCard from "src/components/InfoCard";
const Home = () => {
  const { user } = useSelector((state) => state.user);
  const auth = UseAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scrollRef = useRef(null);
  const [sheduleRoom, setSheduleRoom] = useState();

  useEffect(() => {
    if (auth.user) {
      dispatch(setUser(auth.user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      const contentWidth = container?.scrollWidth / 2;

      const handleScroll = () => {
        if (container.scrollLeft <= 0) {
          // Scroll to the end of the first set
          container.scrollLeft = contentWidth;
        } else if (container?.scrollLeft >= contentWidth * 2) {
          // Scroll to the start of the second set
          container.scrollLeft = contentWidth;
        }
      };

      container.scrollLeft = contentWidth; // Initialize scroll position

      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    // fetchLiveRooms();
    fetchScheduleRoom();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const auth = searchParams.get("auth");
    if (auth) {
      handleAuth(auth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleAuth = async (token) => {
    try {
      const response = await apiClients.verifyToken(token);
      if (response?.token) {
        // dispatch(setUser(response.data));
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("access_token", JSON.stringify(response.token));
        if (response?.data?.role?.name === "Administrator") {
          navigate("/organization/dashboard", { replace: true });
        } else {
          navigate("/room", { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -240, // Adjust the scroll distance
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: 240, // Adjust the scroll distance
        behavior: "smooth",
      });
    }
  };

  function formatDateRange(startDateStr, endDateStr) {
    // Parse the input date strings into Date objects
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Function to format a single date
    function formatDate(date) {
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      return date.toLocaleDateString("en-US", options);
    }

    // Function to format time
    function formatTime(date) {
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
      return `${hours}:${formattedMinutes} ${ampm}`;
    }

    // Format both dates and times
    const formattedStartDate = formatDate(startDate);
    // const formattedEndDate = formatDate(endDate);
    const formattedStartTime = formatTime(startDate);
    const formattedEndTime = formatTime(endDate);

    // Combine the formatted date and time
    return {
      formattedStartDate,
      formattedStartTime,
      formattedEndTime,
    };
  }

  const handleCopy = (id) => {
    window.open(`${window.location.origin}/room/${id}/join`);
  };

  const fetchScheduleRoom = async () => {
    try {
      const response = await apiClients.scheduleCount(true);
      if (response?.data) {
        setSheduleRoom(response.data);
        // const data1 = response.data.filtered
        // setLiveRooms(prevData => [prevData, ...data1,...data1,...data1,...data1, ...data1,...data1,...data1]);
      }
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div style={{ backgroundColor: "white" }}>
      <Header
        backgroundImage={
          "https://atlearn.in/wp-content/uploads/2024/02/services-scaled.webp"
        }
        showBreadcumb={false}
      />
      {/* {!user?.user && <Carousel slides={slides} />} */}
      <Carousel slides={slides} active={user?.user ? true : false} />

      <div className="px-4 flex flex-col md:flex-row lg:flex-row max-w-[82.50rem] m-auto sm:mt-10 mt-32 -mb-10">
        <span
          className="text-secondary font-bold tracking-[0.3em] font-fredoka uppercase -mt-0 mb-6"
          id="span-1"
        >
          Tailored Education for Every Learner
        </span>
      </div>
      <InfoSection info={PersonalizedLearning} />

      <div className=" mb-20">
        <Features features={features} />
      </div>
      <div className=" mb-6">
        <InfoSection info={CentralizeLearning} rounded />
      </div>

      {sheduleRoom?.length > 0 && (
        <div className="md:px-24 px-2 mt-10">
          <div className="flex-wrap justify-between flex mt-[calc(15px_*_-1)] mx-[calc(20px/_">
            <div className="px-4 w-full max-w-full mt-3.5 md:w-auto">
              <div className="mb-[calc(60px_-_22px)]">
                <span
                  className="text-secondary font-bold tracking-[0.3em] font-fredoka uppercase -mt-0 mb-6"
                  id="span-1"
                >
                  Come and Be part of the exciting live room listed below
                </span>

                <HeaderText>Join Meetings</HeaderText>
              </div>
            </div>

            <div className="self-end px-4 w-full max-w-full mt-3.5 text-white md:w-auto">
              <div className="mb-[calc(60px_-_9px)]" id="div-5">
                <button
                  onClick={scrollLeft}
                  className="bg-secondary items-center cursor-pointer justify-center inline-flex w-14 h-14 mr-1 rounded-full"
                >
                  <FaArrowLeft />
                </button>

                <button
                  onClick={scrollRight}
                  className="bg-secondary items-center cursor-pointer justify-center inline-flex w-14 h-14 rounded-full"
                >
                  <FaArrowRight />
                </button>
              </div>
            </div>
          </div>

          <div className="overflow-x-auto no-scrollbar snap-x" ref={scrollRef}>
            <div className="flex gap-2">
              {sheduleRoom?.map((item, index) => (
                <div
                  key={index}
                  className="p-8 w-80 group snap-start relative mb-10 rounded-3xl border-t-8 border-primary w-60 flex-shrink-0 hover:bg-primary hover:border-secondary duration-500 flex flex-col"
                  style={{ boxShadow: "0 12px 15px rgba(73, 13, 89, 0.5)" }}
                >
                  <div className="bg-white bottom-0 left-0 absolute top-0 z-[-1] rounded-3xl" />
                  <div className="bg-fuchsia-950 bottom-0 left-0 absolute top-0 z-[-2] rounded-3xl" />
                  <div className="bg-secondary bottom-0 left-0 absolute top-0 z-[-3] rounded-3xl" />
                  {item?.meeting?.room?.online && (
                    <div className="absolute top-2 right-5">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <CircleIcon
                          sx={{
                            color: "green",
                            fontSize: "0.8rem",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "16px",
                            color: "green",
                          }}
                        >
                          online
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="text-white items-center flex-col text-sm font-semibold justify-center uppercase inline-flex w-24 h-24 mb-6 rounded-full font-fredoka">
                    <img src={videoCallIcon} alt="" />
                  </div>
                  <h3 className="text-black text-3xl font-semibold mb-3 font-fredoka group-hover:text-white">
                    {item?.name}
                  </h3>
                  <p className="font-large font-fredoka text-[#444444] group-hover:text-white">
                    {
                      formatDateRange(
                        item.meeting.startDate,
                        item.meeting.endDate
                      ).formattedStartDate
                    }
                  </p>
                  <p className="font-large font-fredoka text-[#444444] group-hover:text-white">
                    {
                      formatDateRange(
                        item.meeting.startDate,
                        item.meeting.endDate
                      ).formattedStartTime
                    }{" "}
                    -{" "}
                    {
                      formatDateRange(
                        item.meeting.startDate,
                        item.meeting.endDate
                      ).formattedEndTime
                    }
                  </p>
                  <div className="flex-grow"></div>{" "}
                  {/* This takes up the remaining space */}
                  <div className="flex items-center mt-1">
                    <Button
                      onClick={() => handleCopy(item.meeting.room.friendly_id)}
                    >
                      Join
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <div
        className="relative bg-no-repeat bg-cover text-neutral-700 py-24 w-full min-h-screen bg-center flex items-center justify-center"
        id="section-1"
      >
        {/* Background image with reduced opacity */}
        <div
          style={{ backgroundImage: `url(${backgroundcta})`, opacity: 0.3 }} // Adjust opacity here
          className="absolute inset-0 bg-no-repeat bg-cover bg-center"
        ></div>

        {/* Content on top */}
        <div className="relative w-full px-4 md:px-6 lg:px-8 flex flex-col items-center">
          <div className="bg-primary md:rounded-tl-custom-tl md:rounded-tr-custom-tr md:rounded-br-custom-br md:rounded-bl-custom-bl p-8 md:p-16 text-center w-full max-w-5xl flex flex-col justify-center items-center">
            <div className="flex justify-center mb-8">
              <div className="bg-secondary text-white text-5xl leading-none h-24 w-24 rounded-full flex justify-center items-center">
                {/* <TfiAlarmClock size={55} className="mb-2" /> */}
                <img src={classRoomIcon} alt="" className="w-16"/>
              </div>
            </div>

            <h1 className="text-white text-3xl md:text-4xl lg:text-5xl font-fredoka font-bold mb-4">
              Tailored for Educational Institutions
            </h1>

            <p className="text-white text-[18px] mb-8 font-fredoka">
              Empowering Learning Communities Across Diverse Institutions
            </p>

            <div className="space-y-4 font-fredoka text-sm md:text-[18px] md:w-[80%] ">
              <div className="bg-white py-3 md:px-4 px-2 rounded-full flex items-center">
                <div style={{ width: "40%" }}>
                  <span className="font-semibold md:pr-4 pr-2">
                    Servicing for
                  </span>
                </div>
                <span>|</span>
                <span className="font-semibold pl-4 md:pl-5">
                  Schools, colleges, training institutes.
                </span>
              </div>

              <div className="bg-white py-3 md:px-4 px-2 rounded-full flex items-center">
                <div style={{ width: "40%" }}>
                  <span className="font-semibold md:pr-4 pr-2">
                    Collaborating with
                  </span>
                </div>
                <span>|</span>
                <span className="font-semibold pl-4 md:pl-5">
                  Interactive learning environments.
                </span>
              </div>

              <div className="bg-white py-3 md:px-4 px-2 rounded-full flex items-center">
                <div style={{ width: "40%" }}>
                  <span className="font-semibold md:pr-4 pr-2">
                    Sharing expertise
                  </span>
                </div>
                <span>|</span>
                <span className="font-semibold pl-4 md:pl-5">
                  Cultivating e-learning communities
                </span>
              </div>

              <div className="bg-white py-3 md:px-4 px-2 rounded-full flex items-center">
                <div style={{ width: "40%" }}>
                  <span className="font-semibold md:pr-4 pr-2">Developed</span>
                </div>
                <span>|</span>
                <span className="font-semibold pl-4 md:pl-5">
                  Robust enterprise & educational app
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <section className="text-neutral-700 w-full">
          <div
            className="flex flex-col md:flex-co lg:flex-row items-center max-w-[82.50rem] m-auto"
            id="div-1"
          >
            <div
              // className="flex min-h-[0.06rem] md:w-2/4  lg:w-full"
              className="flex  items-center md:w-2/4 lg:w-full"
              id="div-2"
            >
              <div
                className="content-center items-center flex-wrap pl-3.5 pr-10 flex w-full"
                id="div-3"
              >
                <div
                  className="flex-col justify-center w-full"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div id="div-4">
                    <div>
                      <div className="rounded-3xl overflow-hidden">
                        <img
                          alt="wp-content"
                          className="w-[37.81rem] h-auto max-w-full"
                          src={FAQAtlearn}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex   min-h-[0.06rem] md:w-full  " id="div-5">
              <div
                className="content-center items-center flex-wrap pl-10 pr-3.5 flex w-full"
                id="div-6"
              >
                <div
                  className="flex-col justify-center w-full mb-5"
                  style={{
                    alignItems: "initial",
                  }}
                >
                  <div className="-mb-5">
                    <div className="mb-[calc(60px_-_22px)]" id="div-7">
                      <span
                        className="text-secondary font-bold uppercase font-fredoka tracking-[0.3em] -mt-0 mb-6"
                        id="span-1"
                      >
                        Answers to Common Questions
                      </span>
                      <HeaderText> Atlearn LMS FAQ Guide</HeaderText>
                    </div>
                  </div>
                </div>

                <Accordion items={faqs} />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="md:mt-4 lg:mt-0">
        <InfoSection info={ForSchool} />
      </div>
      <div>
        <InfoSection info={CourseManagementInfo} />
        <InfoCard cardData={CourseManagementCards} />
      </div>

      <div>
        <InfoSection info={E_learning} />
      </div>

      <InfoSection info={EmployeeTraining} imageSide />
      <div>
        <InfoSection info={AtlearnMobile} imageSize="20rem" />
      </div>

      <div className="">
        <InfoSection info={OutcomeDriven} imageSide rounded />
      </div>
      <div className="">
        <InfoSection info={PersonalizedLearningPaths} />
      </div>

      <Footer />
    </div>
  );
};

export default Home;
