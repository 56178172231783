import Header from "../../components/Header";
import InfoSection from "../../components/InfoSection";
import Features from "../../components/Features";
import Button from "../../components/Button";
import bg_img from "src/assets/images/Services/LMS/bg-123.png";
import serviceBg from "../../assets/images/Services/LMS/MaterialManagement.webp";
import Footer from "src/components/Footer";
import { useNavigate } from "react-router-dom";
import InfoCard from "src/components/InfoCard";
import {
  ManagementHub,
  EffectiveTools,
  Reports,
  EducationGap,
  features,
  Assessment,
  EfficientDigital,
  AtlearnForum,
  Empowering,
  LMS,
  Personalized,
  CourseManagement,
  CourseManagementCards,
  StudentManagementInfo,
  StudentManagementCard,
  UserManagementInfo,
  UserManagementCards,
} from "src/Data/LmsData";
function Services() {
  const navigate = useNavigate();

  return (
    <div>
      <Header
        backgroundImage={"/cover_images/services.svg"}
        pageTitle={"LMS"}
        subTitle={
          "Unlock limitless learning with our powerful and intuitive LMS platform!"
        }
        cta={"Join now"}
      />
      <div className="  mt-4 md:mt-10">
        <InfoSection info={ManagementHub} />
      </div>

      <div>
        <InfoSection info={CourseManagement} />
        <InfoCard cardData={CourseManagementCards} />
      </div>

      <div className="mt-4 md:mt-10">
        <InfoSection info={EffectiveTools} imageSide />
      </div>

      <div className="mt-4 md:mt-10">
        <InfoSection info={StudentManagementInfo} />
        <InfoCard cardData={StudentManagementCard} />
      </div>

      <div
        style={{
          backgroundImage: `url(${bg_img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          // height: "100vh", // or any desired height
          objectFit: "fill",
        }}
        className="pt-8 pb-8 "
      >
        <InfoSection info={Reports} rounded />
      </div>

      <div className="mt-4 md:mt-10">
        <InfoSection info={UserManagementInfo} />
        <InfoCard cardData={UserManagementCards} />
      </div>

      <section className=" text-neutral-700 w-full mt-4 md:mt-10 ">
        <InfoSection info={LMS} text_center={true} />
      </section>

      <div>
        <Features features={features} />
      </div>
      <div
        style={{
          backgroundImage: `url(${bg_img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          // height: "100vh", // or any desired height
          objectFit: "fill",
        }}
        className="pt-8 pb-8"
      >
        <InfoSection info={Personalized} />
      </div>

      <div className="mt-4 md:mt-8">
        <InfoSection info={EducationGap} rounded />
      </div>

      <div className="mt-4 md:mt-10 ">
        <InfoSection info={Assessment} rounded imageSide />
      </div>

      <div className="mt-4 md:mt-10  ">
        <InfoSection info={EfficientDigital} />
      </div>
      <div className="mt-4 md:mt-10  mb-4 md:mb-10">
        <InfoSection info={AtlearnForum} rounded imageSide />
      </div>
      <div
        style={{
          backgroundImage: `url(${bg_img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          // height: "100vh", // or any desired height
          objectFit: "fill",
        }}
        className="pt-8 pb-8"
      >
        <InfoSection info={Empowering} />
      </div>

    
      <section
        className="relative text-neutral-700 bg-no-repeat bg-cover py-28"
        id="section-1"
      >
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${serviceBg})`,
            opacity: 0.5,
          }}
        ></div>

        <div className="relative flex m-auto">
          <div className="flex min-h-[0.06rem] md:w-full" id="div-1">
            <div className="content-start flex-wrap flex w-full">
              <section className="w-full">
                <div className="flex max-w-[82.50rem] m-auto" id="div-2">
                  <div className="flex min-h-[0.06rem] md:w-full" id="div-3">
                    <div className="content-start flex-wrap px-4 flex w-full">
                      <div
                        className="flex-col justify-center w-full"
                        style={{ alignItems: "initial" }}
                      >
                        <div>
                          <div
                            className="bg-fuchsia-950 md:rounded-bl-full md:rounded-br-full md:rounded-tl-full md:rounded-tr-3xl rounded-xl sm:p-20 p-10 max-w-[38.13rem]"
                            id="div-4"
                          >
                            <h2 className="text-white text-[30px] leading-none font-semibold -mt-1 mb-5 font-fredoka">
                              Simplify Your Educational Material Management
                            </h2>

                            <p className="text-zinc-300 text-lg mb-8 font-fredoka">
                              Manage and distribute educational materials
                              effortlessly with Atlearn&apos;s streamlined book
                              feature.
                            </p>

                            <Button
                              onClick={() => navigate("/signup")}
                              text={"Start Registration"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Services;
