// import logo from './img/logo.svg';
// import logo from "../assets/img/logo.svg"
// import logo from "../assets/img/logo/atlearn_logo.png"
// import logo from "src/images/logo/atlearn_logo.png"
import logo from "src/assets/images/Logo/atlearnMainLogo.svg"



const Logo = () => {
  return (
    <div>
    <img src={logo} className='w-60' alt="logo" />
    </div>
  )
}

export default Logo