import Button from "./Button";
import { FaEnvelope } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import FooterImg1 from "../assets/img/image1.webp";
import FooterImg2 from "../assets/img/image2.webp";
import FooterImg3 from "../assets/img/image3.webp";
import { useNavigate } from "react-router-dom";
import atlearn_logo from "src/assets/images/Logo/atlearnSecondLogo.svg";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section
        className='text-neutral-700 bg-[url("https://atlearn.in/wp-content/uploads/2023/08/footer-bg-1-1-1.png")] bg-no-repeat bg-cover pb-20 pt-16 bg-fuchsia-950'
        id="section-1"
        style={{
          backgroundPosition: "50% 50%",
        }}
      >
        <div className="flex m-auto">
          <div className="flex min-h-[0.06rem] md:w-full" id="div-1">
            <div className="content-start flex-wrap flex w-full">
              <section className="w-full">
                <div
                  className="flex max-w-[82.50rem] m-auto flex-col lg:flex-row items-center justify-center gap-5"
                  id="div-2"
                >
                  <div
                    className="flex min-h-[0.06rem] text-rose-600 md:w-80  lg:w-full"
                    id="div-3"
                  >
                    <div className="content-center items-center flex-wrap px-4 flex w-full">
                      <div
                        className="flex-col justify-center items-center w-full"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div>
                          <div className="max-w-[16.88rem]" id="div-4">
                            {/* <Logo /> */}
                            <img
                              // style={{
                              //   mixBlendMode: "color-burn",
                              // }}
                              alt=""
                              src={atlearn_logo}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex min-h-[0.06rem] md:w-[57.184%]  lg:w-full"
                    id="div-5"
                  >
                    <div className="content-center items-center flex-wrap px-4 flex w-full">
                      <div
                        className="flex-col justify-center w-full"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div id="div-6">
                          <div>
                            <ul
                              className="flex-col justify-end text-right flex list-none"
                              id="ul-1"
                            >
                              <li
                                className="items-center justify-end flex"
                                id="li-1"
                              >
                                <div id="div-7">
                                  <svg
                                    className="inline w-10 h-10"
                                    fill="none"
                                    height="40"
                                    viewBox="0 0 38 40"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      clipPath="url(#clip0_19_2086)"
                                      fill="none"
                                    >
                                      <path
                                        d="M27.7086 0.605469H2.3753C1.4253 0.605469 0.791992 1.24907 0.791992 2.21451V37.614C0.791992 38.5795 1.4253 39.2231 2.3753 39.2231H18.2086V36.0049H3.95861V3.82363H26.1253V18.3052H29.292V2.21451C29.292 1.24907 28.6586 0.605469 27.7086 0.605469Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                      <path
                                        d="M8.70831 10.2601C9.58275 10.2601 10.2916 9.53969 10.2916 8.65104C10.2916 7.76239 9.58275 7.04199 8.70831 7.04199C7.83387 7.04199 7.125 7.76239 7.125 8.65104C7.125 9.53969 7.83387 10.2601 8.70831 10.2601Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                      <path
                                        d="M8.70831 18.305C9.58275 18.305 10.2916 17.5846 10.2916 16.696C10.2916 15.8073 9.58275 15.0869 8.70831 15.0869C7.83387 15.0869 7.125 15.8073 7.125 16.696C7.125 17.5846 7.83387 18.305 8.70831 18.305Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                      <path
                                        d="M8.70831 26.3504C9.58275 26.3504 10.2916 25.63 10.2916 24.7414C10.2916 23.8527 9.58275 23.1323 8.70831 23.1323C7.83387 23.1323 7.125 23.8527 7.125 24.7414C7.125 25.63 7.83387 26.3504 8.70831 26.3504Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                      <path
                                        d="M22.9583 7.0415H11.875V10.2597H22.9583V7.0415Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                      <path
                                        d="M19.7917 15.0869H11.875V18.3051H19.7917V15.0869Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                      <path
                                        d="M16.625 23.1323H11.875V26.3505H16.625V23.1323Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                      <path
                                        d="M34.0417 29.5689C34.0417 33.1089 31.1917 36.0052 27.7084 36.0052C24.2251 36.0052 21.375 33.1089 21.375 29.5689C21.375 26.029 24.225 23.1327 27.7083 23.1327C28.5 23.1327 29.45 23.2936 30.2417 23.6154L31.5083 20.7191C26.7583 18.6273 21.0583 20.88 19 25.7072C16.9417 30.5344 19.1583 36.327 23.9083 38.4188C28.6583 40.5106 34.3583 38.2579 36.4166 33.4307C36.8916 32.1434 37.2083 30.8562 37.2083 29.5689H34.0417Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                      <path
                                        d="M32.9336 23.6152L27.7086 28.9252L25.6503 26.8333L23.4336 29.086L26.6003 32.3042C27.2336 32.9478 28.1836 32.9478 28.817 32.3042L35.1503 25.868L32.9336 23.6152Z"
                                        fill="rgb(255, 214, 0)"
                                      />
                                    </g>
                                    <defs>
                                      <rect
                                        className="w-10 h-10"
                                        fill="rgb(255, 255, 255)"
                                        height="38.6176"
                                        transform="translate(0 0.605469)"
                                      />
                                    </defs>
                                  </svg>
                                </div>

                                <span className="text-white md:text-3xl text-[18px] font-semibold pl-2 font-fredoka md:truncate">
                                  Enrol in a course now!
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex min-h-[0.06rem] text-white font-semibold capitalize md:w-80  lg:w-full"
                    id="div-8"
                  >
                    <div className="content-center items-center flex-wrap px-4 flex w-full">
                      <div
                        className="flex-col justify-center w-full"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div>
                          <div className="text-center lg:text-right">
                            <Button
                              onClick={() => navigate("/contact")}
                              text={"Contact Now"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="pt-20 w-full" id="section-2">
                <div
                  className="flex max-w-[82.50rem] m-auto flex-col  lg:flex-row "
                  id="div-9"
                >
                  <div
                    className="flex min-h-[0.06rem] w-full  lg:w-[40%] mb-5 lg:mb-0"
                    id="div-10"
                  >
                    <div className="content-start flex-wrap px-4 flex w-full">
                      <div
                        className="flex-col justify-center w-full mb-5 text-4xl text-white font-semibold"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div className="-mt-1 -mb-1">
                          <h3 className="font-fredoka">
                            Giving your child the best start in life
                          </h3>
                        </div>
                      </div>

                      <div
                        className="flex-col justify-center w-full mb-5 text-white"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div>
                          <p className="mb-5 font-fredoka  ">
                            In the realm of online education, Atlearn offers a
                            wide array of services tailored to meet the diverse
                            needs of our clients.
                          </p>
                        </div>
                      </div>

                      <div className="flex gap-2 w-full">
                        <img
                          alt=""
                          className="w-36 h-32 object-cover max-w-[30%] md:max-w-full rounded-2xl"
                          src={FooterImg1}
                        />
                        <img
                          alt=""
                          className="w-36 h-32 object-cover max-w-[30%] md:max-w-full rounded-2xl"
                          src={FooterImg2}
                        />
                        <img
                          alt=""
                          className="w-36 h-32 object-cover max-w-[30%] md:max-w-full rounded-2xl"
                          src={FooterImg3}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex min-h-[0.06rem] text-white md:w-[33.333%]   mb-5 lg:mb-0"
                    id="div-12"
                  >
                    <div
                      className="content-start flex-wrap pl-11 pr-3.5 flex w-full"
                      id="div-13"
                    >
                      <div
                        className="flex-col text-3xl font-semibold justify-center w-full -mt-1 mb-4"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div>
                          <h3 className="font-fredoka">Get In Touch</h3>
                        </div>
                        <div className="w-48 h-1 rounded-full bg-yellow-300 mt-1" />
                      </div>

                      <div
                        className="flex-col justify-center w-full max-w-full mb-5 text-lg"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div className="flex items-center font-fredoka">
                          <div className="bg-yellow-400 font-light h-11 leading-10 mr-3 text-center align-middle w-11  border-2 border-black border-solid rounded-full flex justify-center items-center">
                            <FaEnvelope color="black" fill="black" />
                          </div>
                          support@atlearn.in
                        </div>
                      </div>

                      <div
                        className="flex-col justify-center w-full max-w-full text-lg"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div
                          className="flex items-center font-fredoka"
                          id="li-3"
                        >
                          <div className="bg-yellow-400 font-black h-11 leading-10 mr-3 text-center align-middle w-11 border-2 border-black border-solid rounded-full flex justify-center items-center">
                            <FaMobileAlt fill="black" />
                          </div>
                          +91 8015801639
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="flex min-h-[0.06rem] text-white md:w-[33.333%]  lg:w-2/4"
                    id="div-14"
                  >
                    <div
                      className="content-start flex-wrap pl-11 pr-3.5 flex w-full"
                      id="div-15"
                    >
                      <div
                        className="flex-col text-3xl font-semibold justify-center w-full -mt-1 mb-2"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div>
                          <h3 className="font-fredoka">Useful Services</h3>
                        </div>
                        <div className="w-60  h-1 rounded-full bg-yellow-300 mt-1" />
                      </div>

                      <div
                        className="flex-col justify-center w-full text-lg"
                        style={{
                          alignItems: "initial",
                        }}
                      >
                        <div className="mt-3">
                          <div className="mb-11 rounded-3xl" id="div-16">
                            <div>
                              <ul className="grid-cols-[repeat(2,_1fr)] grid-rows-[41px_41px_41px_41px] pt-1 grid list-none font-fredoka">
                                <li>
                                  <div
                                    onClick={() => navigate("/contact")}
                                    className=" cursor-pointer w-max max-w-full mb-6 rounded-full flex items-center gap-2 hover:text-secondary"
                                  >
                                    <i className="w-2 h-2 rounded-full bg-yellow-300" />
                                    Contact
                                  </div>
                                </li>

                                {/* <li>
                                  <a
                                    className=" w-max max-w-full mb-6 rounded-full flex items-center gap-2"
                                    href="https://atlearn.in/teachers/"
                                  >
                                    <i className="w-2 h-2 rounded-full bg-yellow-300" />
                                    Teachers
                                  </a>
                                </li> */}

                                <li>
                                  <div
                                    onClick={() => navigate("/faq")}
                                    className=" cursor-pointer w-max max-w-full mb-6 rounded-full flex items-center gap-2 hover:text-secondary"
                                  >
                                    <i className="w-2 h-2 rounded-full bg-yellow-300" />
                                    FAQ
                                  </div>
                                </li>

                                <li>
                                  <div
                                    onClick={() => navigate("/students")}
                                    className=" cursor-pointer w-max max-w-full mb-6 rounded-full flex items-center gap-2 hover:text-secondary"
                                  >
                                    <i className="w-2 h-2 rounded-full bg-yellow-300" />
                                    Students
                                  </div>
                                </li>

                                <li>
                                  <div
                                    onClick={() => navigate("/about")}
                                    className=" cursor-pointer w-max max-w-full mb-6 rounded-full flex items-center gap-2 hover:text-secondary"
                                  >
                                    <i className="w-2 h-2 rounded-full bg-yellow-300" />
                                    About
                                  </div>
                                </li>

                                <li>
                                  <div
                                    onClick={() => navigate("/school")}
                                    className=" cursor-pointer w-max max-w-full mb-6 rounded-full flex items-center gap-2 hover:text-secondary"
                                  >
                                    <i className="w-2 h-2 rounded-full bg-yellow-300" />
                                    School
                                  </div>
                                </li>

                                <li>
                                  <div
                                    onClick={() => navigate("/blog")}
                                    className=" cursor-pointer cursor-pointer w-max max-w-full mb-6 rounded-full flex items-center gap-2 hover:text-secondary"
                                  >
                                    <i className="w-2 h-2 rounded-full bg-yellow-300" />
                                    Blogs
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <div>
        <section className=" text-neutral-700 py-8 text-center bg-yellow-400 flex flex-col lg:flex-row items-center justify-evenly gap-4">
          <p className="font-semibold text-lg">Copyright © 2024 Atlearn.</p>

          <div className="flex flex-col lg:flex-row gap-4 font-fredoka text-md ">
            <div
              onClick={() => navigate("/privacy-policy")}
              className="  cursor-pointer  rounded-full flex items-center gap-2 hover:text-secondary"
            >
              Privacy Policy
            </div>
            <div className=" hidden lg:block border-l-2 border-black-500 h-6"></div>
            <div
              onClick={() => navigate("/terms-and-conditions")}
              className="   cursor-pointer  rounded-full flex items-center gap-2 hover:text-secondary"
            >
              Terms & Conditions
            </div>
            <div className=" hidden lg:block border-l-2 border-black-500 h-6"></div>
            <div
              onClick={() => navigate("/pricing-policy")}
              className="   cursor-pointer rounded-full flex items-center gap-2 hover:text-secondary"
            >
              Pricing Policy
            </div>
            <div className="hidden lg:block border-l-2 border-black-500 h-6"></div>
            <div
              onClick={() => navigate("/cancellation-refund-policy")}
              className="   cursor-pointer rounded-full flex items-center gap-2 hover:text-secondary"
            >
              Cancellation/Refund Policy
            </div>
          </div>
          <div className="flex ">
            <a
              className="bg-white w-12 h-12 mr-1.5 rounded-full flex justify-center items-center"
              href="https://www.facebook.com/Atlearn.in"
            >
              <svg
                className="cursor-pointer w-8 h-8"
                fill="rgb(0, 0, 0)"
                viewBox="0 0 320 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                  fill="rgb(0, 0, 0)"
                />
              </svg>
            </a>

            <a
              className="bg-white w-12 h-12 mr-1.5 rounded-full flex justify-center items-center"
              href="https://www.instagram.com/atlearn_in/"
            >
              <svg
                className="cursor-pointer w-8 h-8"
                fill="rgb(0, 0, 0)"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9 114.9-51.3 114.9-114.9-51.3-114.9-114.9-114.9zm0 188.6c-40.7 0-73.7-33-73.7-73.7s33-73.7 73.7-73.7 73.7 33 73.7 73.7-33 73.7-73.7 73.7zm146.4-194.7c0 14.9-12.1 27-27 27s-27-12.1-27-27 12.1-27 27-27 27 12.1 27 27zm76.1 27.2c-.1-56.4-11.5-106.4-60.4-155.3S331.5 0 275.1 0c-56.4 0-106.4 11.5-155.3 60.4S0 136.6 0 193v125c0 56.4 11.5 106.4 60.4 155.3s98.9 60.4 155.3 60.4h125c56.4 0 106.4-11.5 155.3-60.4s60.4-98.9 60.4-155.3v-125c.1-56.4-11.4-106.4-60.4-155.3zM398.8 398.8c-35.6 35.6-83.3 55.2-134.8 55.2h-125c-51.5 0-99.2-19.6-134.8-55.2s-55.2-83.3-55.2-134.8v-125c0-51.5 19.6-99.2 55.2-134.8s83.3-55.2 134.8-55.2h125c51.5 0 99.2 19.6 134.8 55.2s55.2 83.3 55.2 134.8v125c0 51.5-19.6 99.2-55.2 134.8z"
                  fill="rgb(0, 0, 0)"
                />
              </svg>
            </a>

            <a
              className="bg-white w-12 h-12 mr-1.5 rounded-full flex justify-center items-center"
              href="https://www.linkedin.com/company/atlearn/"
            >
              <svg
                className="cursor-pointer w-8 h-8"
                fill="rgb(0, 0, 0)"
                viewBox="0 0 448 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                  fill="rgb(0, 0, 0)"
                />
              </svg>
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Footer;
