// import BannerimgAtlearnHome from "src/assets/images/home/BannerimgAtlearnHome.webp";
import BannerimgAtlearnHome from "src/assets/images/home/Interactive Online Learning Platform (1).gif";
// import Frame from "src/assets/images/home/Frame.webp";
import Frame from "src/assets/images/home/Interactive Online Learning Platform (2).gif";
import CourseIcon from "src/assets/icons/course.png";
import trackIcon from "src/assets/icons/track.png";
import NotificationIcon from "src/assets/icons/notification.png";
import ChatIcon from "src/assets/icons/chat.png";
import AssessmentIcon from "src/assets/icons/assessment.png";
import WhiteboardIcon from "src/assets/icons/whiteboard.png";
import AkratechLinkedIn from "src/assets/images/home/Akratech-LinkedIn-1.webp";
import CentralizeImage from "src/assets/images/home/centerLearn.webp";

import ForSchoolStudents from "src/assets/images/home/GroupImg.webp";
// import employee_training from "src/assets/img/for_employee_training.png";
import employee_training from "src/assets/images/home/Employee Training Needs (1).gif";
import mobile_learn from "src/assets/images/home/mobile_learn-12.webp";
import outcome_driven from "src/assets/images/home/Outcome-Driven E-Learning With Analytics Employ Atlearn reports and analytics for stra (1).gif";
import Personlizedimg from "src/assets/images/Services/Bulk-enrollment.webp";
import effortless_course_management1 from "src/assets/images/home/CreateOnlineclass.webp";
import effortless_course_management2 from "src/assets/images/home/Joinanonlineclass.webp";
import effortless_course_management3 from "src/assets/images/home/ViewandRecordclasses.webp";
import effortless_course_management4 from "src/assets/images/home/LiveInteractiveSessions.webp";
import liveIcon from "src/assets/img/live.png";
import onlineclasses from "src/assets/img/online-classes.png";
import joinclasses from "src/assets/img/joinclasses.png";
import recordIcon from "src/assets/images/home/ViewandRecordclassesIcon.svg";




export const slides = [
  {
    image: BannerimgAtlearnHome,
    title: { text: "An Interactive Online Hub", fontSize: 32 },
    title2: { text: "with Dynamic Features", fontSize: 35 },
    description: {
      text: "Atlearn Is A Nurturing Holistic Approach To Learning",
      fontSize: 17.5,
    },
    ctaText: "Apply Today",
  },
  {
    image: Frame,
    title: { text: "Interactive Online", fontSize: 35 },
    title2: { text: "Learning Platform", fontSize: 35 },
    description: {
      text: "Unleash your potential through our dynamic and \n engaging interactive online learning platform.",
      fontSize: 14.4,
    },
    ctaText: "Apply Today",
  },
];

export const faqs = [
  {
    id: 1,
    title: "What are the unique features of Atlearn online classroom",
    description:
      "Whiteboard Interaction : Educators can present, calculate, and diagram on the built-in whiteboard tool while collecting attendee feedback. Instant Messaging (IM) : Our IM feature allows private student conversations within the online meeting site. Evaluation & Testing : Use clear quiz questions with various guidelines to assess student comprehension and aid online learning",
  },
  {
    id: 2,
    title: "What advantages does Atlearn LMS offers to me",
    description:
      "The Atlearn Learning Management System (LMS) makes it simple for instructors to build courses and upload course materials. Atlearn is an effective LMS platform for adding users and students, tracking student progress, making announcements, and communicating with users.",
  },
  {
    id: 3,
    title: "What is a virtual classroom?",
    description:
      "A virtual classroom is an online learning environment that allows teachers and students to communicate, interact, collaborate, and discuss learning materials and activities in real-time.",
  },
];
export const features = [
  {
    icon: CourseIcon,
    title: "Course Creation",
    description:
      "Streamline course creation with our intuitive platform, simplifying the process and empowering educators to design and deliver engaging content seamlessly",
  },
  {
    icon: trackIcon,
    title: "Track Student Progress",
    description:
      "Effortlessly track student progress with our comprehensive system, empowering educators to provide targeted support for personalized learning journeys",
  },
  {
    icon: NotificationIcon,
    title: "Message & Notification",
    description:
      "Streamline communication with instant messaging and notification features, ensuring seamless interaction and timely updates for all users",
  },
  {
    icon: ChatIcon,
    title: "Chat & Record",
    description:
      "Facilitate seamless communication and documentation with integrated chat and recording capabilities, ensuring efficient collaboration and easy access to valuable insights",
  },
  {
    icon: AssessmentIcon,
    title: "Assessment & Quiz",
    description:
      "Manage teacher workload effectively with accessible Optimize learning with efficient assessment and quiz tools, fostering engagement and providing valuable feedback for improved student performanceinformation.",
  },
  {
    icon: WhiteboardIcon,
    title: "Online Whiteboard",
    description:
      "Enhance collaboration with our dynamic online whiteboard, enabling real-time interaction and brainstorming for enriched learning experiences",
  },
];

export const PersonalizedLearning = {
  title: "Personalized Learning Excellence",
  description:
    "At Atlearn, we're revolutionizing the way education is delivered with our cutting-edge online classroom platform. Our innovative solution seamlessly integrates all vital educational elements into one user-friendly application, providing students and educators alike with an intuitive interface for a truly immersive and comprehensive learning journey. Whether you're a student eager to delve into new subjects or an educator striving to deliver top-notch instruction, Atlearn empowers you to unlock your full potential and achieve academic excellence like never before. Join us as we reshape the future of education, one seamless online classroom experience at a time.",
  image: AkratechLinkedIn,
};

export const CentralizeLearning = {
  title: "Centralize Learning With Atlearn",
  description:
    "Our intelligent class management tool empowers teachers to enhance their effectiveness in the classroom by dynamically illustrating complex concepts, fostering engaging learning experiences",
  points: [
    "Send Emails and alerts",
    "Send notifications for classes",
    "Dates for releasing announcements",
    "Weekly, daily, and monthly calendar views",
    "Include files with messages.",
  ],
  image: CentralizeImage,
};

export const ForSchool = {
  title: "For School And Higher Education",
  description:
    "If you are a school, an institution of higher education, or a training center Our Atlearn learning management system is here to help you to get your learning on board.",
  points: [
    "Unified workflow for education, teamwork, assessment, and certification.",
    "Centralized management for courses, tests, attendance, progress, and performance.",
    "Store and administer courses in the Atlearn Learning library.",
    "Flexible learning delivery: live, independent study, blended.",
    "Customize branding and user interface to fit your needs.",
  ],
  image: ForSchoolStudents,
};

export const EmployeeTraining = {
  title: "For Employee Training Needs",
  description:
    "Effective employee training can change both individual workers and entire organizations, which ultimately helps teams get better and keep growing. Using Atlearn LMS will save you time and effort. Our LMS allows you to do the following:",
  points: [
    "Identify the skills gap and address it",
    "Create more clear career trajectories",
    "Quickly update training materials and centralize them",
    "Create and produces reports on the go",
    "Make sure new hires adhere to business standards and increase personal accountability",
  ],
  image: employee_training,
};

export const AtlearnMobile = {
  title: "Atlearn Mobile Learning Can Transform Education",
  description:
    "Our Mobile learning platform comes for Android and iOS devices where learners will log in, check notifications, attend live classes, and access self-paced online sessions from their smartphones and tablets.",
  points: [
    "Notifications and updates, Real-time collaboration",
    "Live classes on mobile, Mobile access to courses",
    "Real-time messaging",
    "Secure streaming",
    "iOS and Android app",
    "ELearning tools and features, Content format compatibility",
  ],
  image: mobile_learn,
};

export const OutcomeDriven = {
  title: "Outcome-Driven E-Learning With Analytics",
  description:
    "Employ Atlearn reports and analytics for strategic teaching decisions. Gain insights on class performance, attendance, and course development trends to enhance educational outcomes",
  points: [
    "Examine attendance reports.",
    "Helps in Class performance evaluation.",
    "Enhance learner retention.",
    "Downloaded Reports in Excel format.",
    "Examine the amount of time spent in class.",
    "Reduce your expenses and efforts.",
    "Examine your course performance.",
  ],
  image: outcome_driven,
};

export const PersonalizedLearningPaths = {
  title: "Personalized Learning Paths",
  description:
    "Managing a large number of students and online courses in an LMS can be challenging. Personalized learning paths make the journey easier for both administrators and learners by assisting learners in grasping a specific topic or training program.",
  points: [
    "Self-assessment with online tests in any subject.",
    "Extensive question bank covers all topic areas.",
    "Create quizzes for individuals or groups.",
    "Interactive games boost engagement and IQ.",
    "Skills assessment improves performance and confidence.",
    "Personalized learning paths from assessment results.",
  ],
  image: Personlizedimg,
};

export const E_learning = {
  title: "Atlearn Nurture E-learning communities",
  description:
    "Our e-learning system nurtures the development of e-learning communities and the transfer of knowledge among various institutions. To improve teamwork, research, and teaching as a component of the higher education system, we have developed reliable enterprise-scale educational applications.",
};

export const CourseManagementInfo={
  title:"Effortless Course Management",
  description:"Utilize our Online Course Creator to effortlessly create, manage, and track courses from anywhere, ensuring seamless organization and progress tracking."
}

export const CourseManagementCards = [
  {
  
    imageSrc: effortless_course_management1,
    color: "bg-lime-600",
    iconSrc: onlineclasses,
    heading: "Create Online class",
    description:
      "With our Online course creator you can easily create, Manage, and Track courses on the go",
  },
  {
   
    imageSrc: effortless_course_management2,
    color: "bg-cyan-500",
    iconSrc: joinclasses,
    heading: "Join an online class",
    description:
      "You can join an enjoyable course with a variety of activities and collaborative tools.",
  },
  {
    imageSrc: effortless_course_management3,
    color: "bg-fuchsia-950",
    iconSrc: recordIcon,
    heading: "View and Record classes",
    description:
      "Attend live lectures and gain access to the class recording for continuous learning",
  },
  {

    imageSrc: effortless_course_management4,
    color: "bg-orange-600",
    iconSrc: liveIcon,
    heading: "Live Interactive Sessions",
    description:
      "Engage actively in real-time discussions and activities for interactive learning",
  },
];
